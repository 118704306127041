<template>
  <div class="user-service-request mb-2">
    <b-card>
      <div v-if="serviceRequest" class="row">
        <div class="col-md-8 map-height-responsive">
          <div id="mapboxLeaflet" style="width: 100%; height: 100%" />
        </div>
        <div class="col-md-4 pt-1" style="background: #f7f7f7">
          <b-card style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 15px">
            <div v-if="serviceRequest && serviceRequest.status === 'WAITING_FOR_PAYMENT' && isUser"
              class="col-md-12 text-center mb-1">
              <button class="btn btn-primary" @click="redirectToPayment">
                {{ $t('Pay Now') }}...
              </button>
            </div>
            <div v-if="serviceRequest && serviceRequest.cancelled_by" class="col-md-12 text-left mb-1 p-0">
              <h4 class="text-danger">
                {{ $t('Service cancelled by: ') }}{{ serviceRequest.cancelled_by }}
              </h4>
              <p class="text-danger">
                {{ $t('Reason: ') }}{{ serviceRequest.cancel_reason }}
              </p>
            </div>
            <div v-if="serviceRequest && serviceRequest.otp" class="col-md-12 text-left mb-1 p-0">
              <h4><strong>{{ $t('OTP') }}: {{ serviceRequest.otp }}</strong></h4>
            </div>
            <div v-if="serviceRequest && serviceRequest.provider" class="d-flex justify-content-between">
              <div class="d-flex justify-content-start align-items-center">
                <div class="mr-1">
                  <img v-if="serviceRequest.provider.picture" :style="{backgroundImage: `url('${$helpers.imageHelper(serviceRequest.provider.picture)}')`}" fluid class="store-logo" />
                </div>
                <div>
                  <div class="order-details">
                    <p class="mb-0">
                      {{ serviceRequest.provider.company_name }}
                    </p>
                    <p class="mb-0">
                      {{ serviceRequest.booking_id }} 
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <span v-if="serviceRequest.status" class="badge badge-pill" :class="`badge-${getStatusColor(serviceRequest.status).className}`">{{ `${getStatusColor(serviceRequest.status).statusName}` }}</span>
                </div>
                <div
                  v-if="(isUser || isProvider) && (serviceRequest.status === 'ASSIGNED' || serviceRequest.status === 'ACCEPTED' || serviceRequest.status === 'SEARCHING')"
                  class="text-center mt-1">
                  <button class="btn btn-primary" @click="rescheduleRequest">
                    {{ $t('Reschedule') }}
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div v-if="serviceRequest && serviceRequest.status === 'WAITING_FOR_USER_APPROVAL' && isUser"
              class="col-md-12 text-center mb-1">
              <p class="mb-0 text-left">
                {{ $t('Provider has updated the service for') }} {{ serviceRequest.schedule_at }}
              </p>
              <button class="btn btn-success mx-1 mt-1" @click="rescheduleService('ACCEPTED')">
                {{ $t('Accept') }}
              </button>
              <button class="btn btn-danger mx-1 mt-1" @click="confirmCancel">
                {{ $t('Reject') }}
              </button>
            </div>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('SERVICE LOCATION') }}
              </p>
              <p v-if="serviceRequest.s_address" class="mb-25">
                {{ serviceRequest.s_address }}
              </p>
              <p v-else class="mb-25">
                {{ serviceRequest.provider ? serviceRequest.provider.current_location : '' }}
              </p>
              <hr>
            </div>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('SERVICES') }}
              </p>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tr v-for="(cart, index) of cartDetails" :key="index">
                    <td class="pl-0 text-black pt-pb-5">
                      {{ cart.provider_service.main_service ? (!isEmpty(cart.provider_service.title) ?
                        cart.provider_service.title : cart.provider_service.main_service.service_name) : '' }}
                    </td>
                    <td class="text-black pt-pb-5 text-right">
                      {{ userCurrency }}{{ cart.service_price }} x {{ cart.quantity }}
                    </td>
                    <td class="text-black pt-pb-5 text-right">
                      {{ userCurrency }}{{ cart.total_service_price }}
                    </td>
                  </tr>
                </table>
              </div>

              <hr>
            </div>
            <div class="trip-fare">
              <p class="small mb-05">
                {{ $t('CHARGES') }}
              </p>
              <table class="table table-borderless">
                <tr v-if="serviceRequest.payment">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Cart Subtotal') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ serviceRequest.payment.total_services_price }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="serviceRequest.payment">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Provider Offer') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ serviceRequest.offer_percent }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="serviceRequest.payment">
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Promocode Discount') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ serviceRequest.payment.discount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="serviceRequest.payment">
                  <td class="pl-0 text-black pt-pb-5">
                    TAX
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ serviceRequest.payment.tax }} {{ userCurrency }}
                  </td>
                </tr>
              </table>

              <div v-if="serviceRequest.payment" class="relative mt-2">
                <hr>
                <b-badge pill variant="primary" class="border-intersection">
                  {{ userCurrency }}{{ serviceRequest.payment.total }}
                </b-badge>
              </div>
            </div>
            <div class="wrap-service-actions d-flex mt-2">
              <a class="mb-0 action-info bg-call" :href="`tel:+${providerInfo.country_code}${providerInfo.mobile}`">
                <img :src="`${$apiUrl}/images/icons/call.svg`">
              </a>
              <p class="mb-0 action-info bg-message cursor-pointer" @click="setChatInfo(false)">
                <img :src="`${$apiUrl}/images/icons/chat.svg`">
              </p>
              <a class="mb-0 action-info bg-whatsapp" target="_BLANK"
                :href="`https://api.whatsapp.com/send?phone=+${providerInfo.country_code}${providerInfo.mobile}`">
                <img :src="require('@/assets/images/app-icons/whatsapp_New.png')">
              </a>
              <p v-if="serviceRequest && serviceRequest.status != 'COMPLETED' && serviceRequest.status != 'CANCELLED'"
                class="mb-0 action-info bg-cancel">
                <img :src="require('@/assets/images/app-icons/Cancel_New.png')" @click="confirmCancel">
              </p>
            </div>
            <hr v-if="!showImageViewHistory && orderHistories.length > 0">
            <div v-if="!showImageViewHistory && orderHistories.length > 0" class="order-history-wrapper">
              <p class="small mb-05">
                {{ $t('History') }}
              </p>
              <app-timeline>
                <app-timeline-item v-for="(item, index) of orderHistories" :key="index"
                  :title="item.status + ' at ' + item.created_at"
                  :variant="index == serviceRequest.history.length - 1 ? 'secondary' : 'primary'" />
              </app-timeline>
            </div>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="serviceRequest.agent" class="delivered-by mt-2">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-auto col-12">
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="mr-1">
                        <img v-if="serviceRequest.agent.picture"
                          :style="{ backgroundImage: `url('${$helpers.imageHelper(serviceRequest.agent.picture)}')` }"
                          fluid class="store-logo" />
                      </div>
                      <div>
                        <p class="text-muted mb-0">{{ $t('Delivery by') }}:</p>
                        <p class="mb-0">{{ serviceRequest.agent.first_name }} {{ serviceRequest.agent.last_name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto col-12">
                    <div class="d-flex address-lines mt-2 cursor-pointer align-items-center">
                      <b-img style="height: 40px" :src="`${$apiUrl}/images/icons/call.svg`" @click="openAgent" />
                      <p class="mb-0 ml-1" @click="openAgent">
                        {{ serviceRequest.agent.mobile }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-auto col-12">
                    <div class="d-flex address-lines mt-2 cursor-pointer align-items-center">
                      <img height="40" :src="`${$apiUrl}/images/icons/chat.svg`" @click="setChatInfo({
                        to_userable_type: 'App\\Models\\Common\\Agent',
                        to_userable_id: serviceRequest.agent.id,
                      })" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <div v-if="showImageViewHistory" class="row d-none d-md-flex justify-content-between">
      <div v-for="(process, index) of orderProcess" :key="index"
        class="col-2 status_icons">
        <b-img v-if="index === 0" :class="{
          active: requestState([
            'ORDERED',
            'DROPPED',
            'ARRIVED',
            'ACCEPTED',
            'STARTED',
            'ASSIGNED',
            'PICKEDUP',
            'PROCESSING',
            'STORECANCELLED',
            'PROVIDEREJECTED',
            'RECEIVED',
            'SEARCHING',
            'COMPLETED',
            'ACCEPTED',
          ]),
        }" :src="`${$apiUrl}/images/icons/sr-step-1.png`" />
        <b-img v-if="index === 1"
          :class="{ active: requestState(['ACCEPTED', 'DROPPED', 'ARRIVED', 'STARTED', 'ASSIGNED', 'PICKEDUP', 'PROVIDEREJECTED', 'RECEIVED', 'PROCESSING', 'COMPLETED']) }"
          :src="`${$apiUrl}/images/icons/sr-step-2.png`" />
        <b-img v-if="index === 2"
          :class="{ active: requestState(['STARTED', 'DROPPED', 'ARRIVED', 'PICKEDUP', 'REACHED', 'COMPLETED']) }"
          :src="`${$apiUrl}/images/icons/sr-step-3.png`" />
        <b-img v-if="index === 3"
          :class="{ active: requestState(['REACHED', 'DROPPED', 'PICKEDUP', 'DELIVERED', 'COMPLETED']) }"
          :src="`${$apiUrl}/images/icons/sr-step-4.png`" />
        <b-img v-if="index === 4" :class="{ active: requestState(['COMPLETED', 'DROPPED']) }"
          :src="`${$apiUrl}/images/icons/sr-step-5.png`" />
        <p>
          <b-badge pill variant="primary" class="mt-1">
            {{ process.text }}
          </b-badge>
        </p>
      </div>
    </div>
    <b-modal id="chat-user-modal-popup-navbar" v-model="modals.chat.open" :no-close-on-backdrop="true" hide-footer
      @hidden="modals.chat.open = false">
      <chat :admin_service="modals.chat.admin_service" :orderable_id="modals.chat.id"
        :to_userable_type="modals.chat.to_userable_type" :to_userable_id="modals.chat.to_userable_id"
        :modalState="modals.chat.open" />
    </b-modal>
    <b-modal id="order-feedback-popup" v-model="showFeedbackModal" :no-close-on-backdrop="true" hide-footer
      @hidden="showFeedbackModal = false">
      <feedback :request-id="serviceIdInfo" :admin-service="'SERVICE'" :user-rated="serviceRequest.user_rated"
        @review-posted="reviewPosted" />
    </b-modal>
    <b-modal v-model="showCancelOrderModal" :title="$t('Please enter cancel reason')" :no-close-on-backdrop="true"
      @ok="cancelTheOrder" @hidden="showCancelOrderModal = false">
      <b-col md="12">
        <b-form-group :label="$t('Cancel Reason')">
          <b-form-input v-model="cancelReason" type="text" />
        </b-form-group>
      </b-col>
    </b-modal>
    <b-modal id="updateRequestModal" v-model="viewUpdateRequestModal" :no-close-on-backdrop="true"
      :title="$t('Update Request')" @hidden="hideUpdateRequestModal" @ok="updateRequestStatus">
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group :label="$t('Schedule time')">
            <custom-calender ref="calenderRef" :hide-submit="true" :timing="storeCurrentTimings"
              :all-timings="providerTimings" :store-slot-info="providerTimings" />
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import moment from 'moment'
import { useUserUi } from './useUser'
import { useProviderUi } from '../provider/useProvider'
import Feedback from '../shared/Feedback.vue'
import CustomCalender from './shared/CustomCalender.vue'
import { getStatusBadgeColor, isEmpty } from '@/utilities'
import Chat from '../shared/chat/ChatNew.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    Chat,
    Feedback,
    CustomCalender,
  },
  data() {
    return {
      viewUpdateRequestModal: false,
      showFeedbackModal: false,
      showChatMessage: false,
      showCancelOrderModal: false,
      cancelReason: '',
      serviceRequest: {},
      center: { lat: 45.508, lng: -73.587 },
      startLocation: null,
      serviceIdInfo: null,
      mapbox: null,
      endLocation: null,
      providerTimings: [],
      storeCurrentTimings: [],
      mapImageStore: {
        STORE: require('@/assets/images/app-icons/store_New.png'),
        AGENT: require('@/assets/images/app-icons/Agent_New.png'),
        HOUSE: require('@/assets/images/app-icons/house_New.png'),
      },
      modals: {
        chat: {
          open: false,
          admin_service: null,
          id: null,
          to_userable_type: null,
          to_userable_id: null,
        },
      },
    }
  },
  computed: {
    storeTimings() {
      if (!this.providerTimings) return null
      return this.providerTimings.find(x => x.day === this.currentDay)
    },
    currentDay() {
      return moment().format('ddd').toUpperCase()
    },
    showImageViewHistory() {
      return !(this.$store.getters['app/windowSize'] <= 767)
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
    isProvider() {
      return window.SuperApp.getters.isProvider()
    },
    isStoreProvider() {
      return this.isStore || this.isProvider
    },
    isUser() {
      return window.SuperApp.getters.isUser()
    },
    cartDetails() {
      if (!this.serviceRequest) return []
      if (!this.serviceRequest.cart_details) return []
      return JSON.parse(this.serviceRequest.cart_details)
    },
    userCurrency() {
      const cart = this.cartDetails
      if (cart && cart[0] && cart[0].provider) {
        return cart[0].provider.currency_symbol
      }
      return ''
    },
    providerInfo() {
      if (!this.serviceRequest) return {}
      if (this.isStoreProvider) {
        if (!this.serviceRequest.user) return {}
        return this.serviceRequest.user
      }
      if (!this.serviceRequest.provider) return {}
      return this.serviceRequest.provider
    },
    userInfo() {
      if (!this.serviceRequest) return {}
      if (!this.serviceRequest.user) return {}
      return this.serviceRequest.user
    },
    providerChatName() {
      if (!this.serviceRequest) return ''
      if (!this.serviceRequest.provider) return ''
      return `${this.serviceRequest.provider.first_name} ${this.serviceRequest.provider.last_name}`
    },
    storeProviderId() {
      if (!this.serviceRequest) return ''
      if (!this.serviceRequest.provider) return ''
      return `${this.serviceRequest.provider.id}`
    },
    userChatName() {
      if (!this.serviceRequest) return ''
      if (!this.serviceRequest.user) return ''
      return `${this.serviceRequest.user.first_name} ${this.serviceRequest.user.last_name}`
    },
    userCustomerId() {
      if (!this.serviceRequest) return ''
      if (!this.serviceRequest.user) return ''
      return this.serviceRequest.user.id
    },
    orderHistories() {
      if (!this.serviceRequest) return []
      if (!this.serviceRequest.history) return []
      return this.serviceRequest.history
    },
    orderProcess() {
      return [
        {
          text: 'REQUESTED',
        },
        {
          text: 'CONFIRMED',
        },
        {
          text: 'ARRIVED',
        },
        {
          text: 'STARTED',
        },
        {
          text: 'COMPLETED',
        },
      ]
    },
  },
  watch: {
    '$route.params.requestId': {
      handler(requestId) {
        this.serviceIdInfo = requestId
        this.reset()
        this.showInitialLoad()
      },
    },
  },
  mounted() {
    this.serviceIdInfo = this.$route.params.requestId
    eventBus.$on('order-updated', payload => {
      // eslint-disable-next-line eqeqeq
      if (payload && payload.serviceId == this.serviceIdInfo && payload.service === 'SERVICE') {
        this.reset()
        this.showInitialLoad()
        if (this.isUser) {
          eventBus.$emit('refresh-on-going-services')
        }
      }
    })
    eventBus.$on('new-request-viewed', requestId => {
      const location = `${window.location.href.split('request/')[0]}request/${requestId}`
      window.history.pushState({}, '', location)
      this.serviceIdInfo = requestId
      this.reset()
      this.showInitialLoad()
      if (this.isUser) {
        eventBus.$emit('refresh-on-going-services')
      }
    })
    this.showInitialLoad()
  },
  destroyed() {
    eventBus.$off('new-request-viewed')
    eventBus.$off('order-updated')
    if (window.serviceRequestRoutes) {
      window.serviceRequestRoutes = null
    }
  },
  methods: {
    moment, isEmpty,
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      const { getProviderTimings } = useUserUi()
      getProviderTimings(`?provider_id=${this.serviceRequest.provider.slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    rescheduleRequest() {
      this.viewUpdateRequestModal = true
    },
    hideUpdateRequestModal() {
      this.viewUpdateRequestModal = false
    },
    openAgent() {
      if (this.serviceRequest && this.serviceRequest.agent && this.serviceRequest.agent.mobile) {
        window.open(`tel:${this.serviceRequest.agent.country_code ? this.serviceRequest.agent.country_code : ''}${this.serviceRequest.agent.mobile}`)
      }
    },
    getStatusColor(status){
      const className = getStatusBadgeColor(status)
      const statusName = status.replaceAll('_', ' ')
      return {
        className,
        statusName
      }
    },
    setChatInfo(toData) {
      if (!this.serviceRequest) {
        showDangerNotification(this, "Order not found");
        return
      }
      console.log(this.serviceRequest, "this.serviceRequest");
      if (!toData) {
        toData = this.isUser ? {
          to_userable_type: 'App\\Models\\Common\\Provider',
          to_userable_id: this.serviceRequest.provider_id,
        } : {
          to_userable_type: 'App\\Models\\Common\\User',
          to_userable_id: this.serviceRequest.user_id,
        }
      }
      this.modals.chat = {
        admin_service: 'SERVICE',
        id: this.serviceRequest.id,
        to_userable_type: toData.to_userable_type,
        to_userable_id: toData.to_userable_id,
        open: true
      }
    },
    updateRequestStatus() {
      const formData = new FormData()
      let date = ''
      let year = ''
      let time = ''
      let month = ''
      let day = ''
      let scheduleDate = ''
      if (this.$refs.calenderRef) {
        scheduleDate = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
      }
      if (scheduleDate) {
        const splitDate = scheduleDate
        date = splitDate
        year = new Date(date).getFullYear()
        month = new Date(date).getMonth() + 1
        day = new Date(date).getDate()
        date = `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`
        if (this.$refs.calenderRef) {
          time = this.$refs.calenderRef.$data.activeTimeSlot
        }
      }
      formData.append('id', this.serviceRequest.id)
      formData.append('request_id', this.serviceRequest.id)
      formData.append('user_id', this.serviceRequest.user_id)
      formData.append('provider_id', this.serviceRequest.provider_id)
      formData.append('status', 'SCHEDULED')
      formData.append('agent_id', this.serviceRequest.agent_id)
      formData.append('date', date)
      formData.append('time', time)
      formData.append('year', year)
      formData.append('day', day)
      formData.append('month', month)
      formData.append('comments', '')
      formData.append('admin_service', 'SERVICE')
      showLoader()
      const { updateProviderRequest } = useProviderUi()
      const requestUrl = this.isUser ? 'user/services/reschedule' : 'provider/service/reschedule'
      updateProviderRequest({
        query: requestUrl,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Request updated successfully')
            eventBus.$emit('provider-sync-app', {
              refreshDashboard: true,
              showNotification: `Reschedule updated for #${this.serviceRequest.booking_id}`,
              providerId: this.serviceRequest.provider_id,
              requestId: this.serviceRequest.id,
            })
            this.showInitialLoad()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    rescheduleService(status) {
      this.$swal({
        title: this.$t('Do you approve the request?'),
        text: '',
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { rescheduleService } = useUserUi()
          const formData = new FormData()
          formData.append('status', status)
          formData.append('request_id', this.serviceRequest.id)
          rescheduleService(formData)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                eventBus.$emit('provider-sync-app', {
                  refreshDashboard: true,
                  showNotification: `Reschedule updated for #${this.serviceRequest.booking_id}`,
                  providerId: this.serviceRequest.provider_id,
                  requestId: this.serviceRequest.id,
                })
                this.showInitialLoad()
                showSuccessNotification(this, 'Request rescheduled successfully')
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    redirectToPayment() {
      if (this.serviceRequest) {
        this.$router.push({
          path: `/user/service/checkout?orderId=${this.serviceIdInfo}&retry=1`,
        })
      }
    },
    reviewPosted(status) {
      if (status) {
        this.showFeedbackModal = false
        this.showInitialLoad()
      } else {
        this.showFeedbackModal = true
      }
    },
    openCall() {
      if (this.isStoreProvider) {
        if (this.serviceRequest && this.serviceRequest.user && this.serviceRequest.user.mobile) {
          window.open(`tel:+${this.serviceRequest.user.country_code ? this.serviceRequest.user.country_code : ''}${this.serviceRequest.user.mobile}`)
        }
      } else if (this.serviceRequest && this.serviceRequest.pickup && this.serviceRequest.pickup.contact_number) {
        window.open(`tel:+${this.serviceRequest.user.country_code ? this.serviceRequest.user.country_code : ''}${this.serviceRequest.pickup.contact_number}`)
      }
    },
    openWhatsapp() {
      if (this.isStoreProvider) {
        if (this.serviceRequest && this.serviceRequest.user && this.serviceRequest.user.mobile) {
          window.open(`https://wa.me/${this.serviceRequest.user.mobile}/?text=Hello`)
        }
      } else if (this.serviceRequest && this.serviceRequest.pickup && this.serviceRequest.pickup.contact_number) {
        window.open(`https://wa.me/${this.serviceRequest.pickup.contact_number}/?text=Hello`)
      }
    },
    showInitialLoad() {
      const { userServiceRequest } = useUserUi()
      const { providerRequestDetail } = useProviderUi()
      showLoader()
      let apiCall = userServiceRequest
      if (!window.SuperApp.getters.isUser()) {
        apiCall = providerRequestDetail
      }
      apiCall(this.serviceIdInfo)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceRequest = data.responseData.service
            if (!this.serviceRequest) {
              showDangerNotification(this, 'No service found')
              return
            }
            if (this.serviceRequest.status === 'COMPLETED' && !this.serviceRequest.user_rated && this.isUser) {
              this.showFeedbackModal = true
            }
            window.serviceRequest = this.serviceRequest
            if (this.serviceRequest.provider) {
              if (this.serviceRequest.s_address) {
                this.runDirection(`${this.serviceRequest.s_latitude}, ${this.serviceRequest.s_longitude}`, `${this.serviceRequest.provider.latitude}, ${this.serviceRequest.provider.longitude}`)
              } else {
                this.atProviderLocation()
              }
            } else {
              this.runDirection()
            }
            this.getProviderTimings()
            hideLoader()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    reset() {
      if (window.serviceRequestRoutes) {
        window.serviceRequestRoutes = null
      }
      if (this.mapbox) {
        this.mapbox.remove()
      }
      this.startLocation = null
      this.endLocation = null
      this.serviceRequest = {}
      this.mapbox = null
    },
    confirmCancel() {
      if (this.isStoreProvider || this.isUser) {
        this.$swal({
          title: this.$t('You want to cancel the request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.showCancelOrderModal = true
          }
        })
      }
    },
    cancelTheOrder() {
      if (this.isStoreProvider) {
        this.cancelStoreOrder()
      } else if (this.isUser) {
        this.cancelUserStoreOrder()
      }
    },
    cancelStoreOrder() {
      showLoader()
      const { storeCancelOrder } = useProviderUi()
      const formData = new FormData()
      formData.append('id', this.serviceRequest.id)
      formData.append('admin_service', 'SERVICE')
      formData.append('reason', this.cancelReason)
      storeCancelOrder(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            eventBus.$emit('provider-sync-app', {
              refreshDashboard: true,
              providerId: window.SuperApp.getters.userInfo().id,
            })
            if (this.isStoreProvider) {
              this.$router.push({
                path: '/provider/requests',
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    cancelUserStoreOrder() {
      showLoader()
      const { userCancelServiceRequest } = useUserUi()
      userCancelServiceRequest({
        id: this.serviceRequest.id,
        reason: this.cancelReason,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            eventBus.$emit('provider-sync-app', {
              refreshDashboard: true,
              providerId: window.SuperApp.getters.userInfo().id,
            })
            eventBus.$emit('refresh-on-going-services')
            if (this.isUser) {
              this.$router.push({
                path: '/user/services',
              })
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    requestState(state) {
      if (!this.serviceRequest) return false
      return state.includes(this.serviceRequest.status)
    },
    setMapView() {
      this.mapbox = window.L.map('mapboxLeaflet').setView([-41.2858, 174.78682], 8)
      const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
      window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: `Map data &copy; ${mapLink}`,
        maxZoom: 18,
      }).addTo(this.mapbox)
    },
    atProviderLocation() {
      try {
        this.setMapView()
        window.SuperApp.actions.addMarker([`${this.serviceRequest.provider.latitude}, ${this.serviceRequest.provider.longitude}`], [this.mapImageStore.STORE], this.mapbox, 'serviceRequestRoutes')
        window.SuperApp.helper.resize()
      } catch (error) {
        console.log(error)
      }
    },
    runDirection(start, end, agent) {
      try {
        const urlRoutes = []
        if (start) urlRoutes.push(start)
        if (end) urlRoutes.push(end)
        if (agent) urlRoutes.push(agent)
        this.setMapView()
        if (urlRoutes.length >= 2) window.SuperApp.actions.createRouting(urlRoutes, [this.mapImageStore.STORE, this.mapImageStore.HOUSE, this.mapImageStore.AGENT], this.mapbox, 'serviceRequestRoutes')
        window.SuperApp.helper.resize()
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.store-logo{border-radius: 50%; height: 60px; width: 60px; background-size: cover; background-position: center;}
.pt-pb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.border-intersection {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  padding: 5px 10px !important;
}

.flex-position {
  padding-bottom: 30px;
  width: 100%;
}

.flex-position span {
  position: absolute;
  bottom: 0;
}

.flex-position img {
  width: 100%;
  height: 120px;
  opacity: 0.3;
}

.flex-position img+span {
  opacity: 0.3;
}

.flex-position img.active,
.flex-position img.active+span {
  opacity: 1;
}

.wrap-service-actions .action-info span {
  display: block;
  width: 100%;
}

.wrap-service-actions .action-info {
  width: 100%;
  text-align: center;
}

.wrap-service-actions .action-info:not(:last-child) {
  margin-right: 5px;
}

/* .bg-call {
  background-color: #4ce5b1;
}

.bg-message {
  background-color: #4252ff;
}

.bg-whatsapp {
  background-color: #0ebe60;
}

.bg-cancel {
  background-color: #f23642;
} */

.wrap-service-actions img {
  width: 50px;
}

@media only screen and (max-width: 767px) {
  .map-height-responsive {
    height: 400px;
  }

  .block-responsive {
    display: block !important;
  }

  .block-img-responsive {
    display: inline-block !important;
    width: 48% !important;
    text-align: center;
  }

  .block-img-responsive span {
    position: static;
  }

  .wrap-service-actions .action-info {
    display: block;
    margin-bottom: 10px !important;
  }

  .wrap-service-actions {
    text-align: center;
  }

  .wrap-service-actions img {
    width: 35px;
  }

  .wrap-service-actions .action-info {
    width: 45%;
    display: inline-block;
  }
}
.status_icons img{
  max-width: 100%;
  height: 200px;
  opacity: 0.4;
  text-align: center;
}
.status_icons img.active{
  max-width: 100%;
  height: 200px;
  opacity: 1;
  text-align: center;
}
</style>
